.nice_select {
  ul {
    list-style: none;
  }
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-right: 1px solid #70707020;
  border-radius: 0px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: flex;
  height: 90px;
  outline: none;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-transform: capitalize;
  width: 100%;
  color: #171717;
  padding: 0px 14px;
  align-items: center;
  justify-content: left;
  .current {
    font-size: 14px;
    color: #171717;
    position: relative;
    font-weight: 500;
    text-transform: capitalize;
  }
  .option {
    font-weight: 400;
    line-height: 38px;
    list-style: none;
    min-height: 38px;
    outline: none;
    padding-left: 15px;
    padding-right: 26px;
    text-align: left;
    transition: all 0.2s;
    font-size: 14px;
  }
  .disabled {
    background-color: transparent;
    color: #999999;
    cursor: default;
  }
}

.serchInputText {
  border: 0;
  border-radius: 0px;
  width: 100%;
  height: 47px;
  padding-left: 25px;
  font-size: 12px;
}

.nice_select .list {
  left: -1px !important;
  right: -1px !important;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 0 1px rgb(68 68 68 / 11%);
  box-sizing: border-box;
  margin-top: 4px;
  padding: 0;
  position: absolute;
  top: 100%;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-25px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 1;
  overflow: auto;
  font-size: 14px;
}

.nice_select:active {
  border-color: #171717;
}

.nice_select::after {
  border-bottom: 2px solid #171717;
  border-right: 2px solid #171717;
  content: '';
  display: block;
  height: 8px;
  margin-top: -5px;
  pointer-events: none;
  position: absolute;
  right: 13px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 8px;
}

@media screen and (max-width: 1024px) {
  .nice_select::after {
    right: 12px;
  }
}

@media screen and (max-width: 768px) {
  .nice_select {
    padding: 8px;
    border-right: none;
    height: 47px !important;
  }
  .nice_select::after {
    right: 14px;
  }
}
@media screen and (max-width: 767px) {
  .nice_select {
    height: 47px;
    width: 95px;
    .current {
      font-size: 12px;
    }
    .list {
      width: 93px;
    }
  }

  .nice_select::after {
    right: 5px !important;
  }
}

@media screen and (max-width: 320px) {
  .nice_select::after {
    right: 14px;
  }

  .nice_select {
    padding: 5px;
    border-right: none;
    .current {
      font-size: 12px;
    }

    .list {
      width: 93px;
    }
  }
}
