.root {
    background-color: #fff;
    border-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0;
  
    .MuiTabs-indicator {
      display: none;
    }
  
    .MuiTab-wrapper {
      text-transform: capitalize;
    }
  
    .MuiButtonBase-root.Mui-selected {
      background-color: #0056b2;
      color: #ffffff;
      box-shadow: none;
      border: 0;
    }
  
    .MuiButtonBase-root.Mui-selected:first-of-type {
      border-top-left-radius: 10px;
    }
  
    .MuiButtonBase-root.Mui-selected:last-child {
      border-top-right-radius: 10px;
    }
  
    .MuiBox {
      padding: 0 !important;
    }
  }
  
  .loginDrawer {
    display: block;
    width: 285px;
    height: auto;
    text-align: center;
    text-transform: capitalize;
  
    svg {
      margin: 6px;
      cursor: pointer;
    }
  }
  
  .row {
    display: flex;
    border-bottom: 1px solid #0056b20d;
    cursor: pointer;
    align-items: center;
  
    img {
      margin: 16px 30px 16px 20px;
    }
  }
  
  .wishlistPopover {
    top: 32px;
    // right: 0px;
    .MuiPaper-root {
      top: 47px !important;
      border: 1px solid #d6d6e8 !important;
      border-radius: 10px;
      width: 388px;
  
      .MuiTab-root {
        width: 30% !important;
        padding: 6px 6px;
        background-color: #f3f3ff;
        margin-left: 3px;
        border-radius: 5px !important;
        margin-right: 3px;
        font-size: 14px;
        font-weight: bold;
        font-family: "Open Sans" !important;
        text-transform: capitalize;
        border: 1px solid #e2e2f0;
        color: #464646;
        cursor: pointer;
      }
  
      .MuiTab-textColorInherit {
        opacity: 9 !important;
      }
  
      .MuiTab-textColorInherit.Mui-selected {
        background-color: #2e72bb !important;
        color: #ffffff !important;
      }
  
      p {
        padding: 0;
      }
    }
  }
  
  .projectDetails {
    border-right: 1px solid #d6d6e8;
    padding: 5px 8px 5px 15px;
  
    .MuiTypography-root a {
      font-size: 14px;
      font-weight: 700;
      font-family: "Open Sans";
      color: #2c2c2c;
    }
  
    .MuiTypography-root {
      font-size: 13px;
      font-weight: 400;
    }
  }
  
  .helloTime {
    ul:nth-of-type(odd) {
      background-color: #f3f3ff;
    }
    ul:nth-of-type(even) {
      background-color: #ededf2;
    }
  }
  
  .listData {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  background-color: #F4F3FE;
  border-radius: 4px;
    li {
      border-bottom: 1px solid #e2e2f0;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: 79px;
      margin-bottom: 10px;
      
  
      &:hover {
        background-color: #e2e2f0;
      }
      &:active {
        background-color: #e2e2f0;
      }
  
      .MuiListItemAvatar-root {
        min-width: 40px;
      }
    }
  
    ul:nth-of-type(odd) {
      background-color: red;
    }
    ul:nth-of-type(even) {
      background-color: green;
    }
  }
  
  .notSeenProperty {
    text-align: center;
    padding: 10px;
  }
  
  .deletedImg {
    text-align: center;
    padding-left: 11px;
  
    .MuiListItemAvatar-root {
      min-width: 39px !important;
    }
  }
  
  .tabRoot {
    .MuiTab-root {
      min-width: 50% !important;
    }
  }
  .projectDetails {
  border-right: 1px solid #D6D6E8 !important;
  padding: 5px 8px 5px 8px !important;
  font-family: 'Open Sans' !important;
  .MuiTypography-root{
    font-family: 'Open Sans' !important;
  }
  .MuiTypography-root {
    font-size: 13px !important;
    font-weight: 400 !important;

  }
}
.projectDetails span a{
font-size: 14px !important;
      font-weight: 700 !important;
      font-family: 'Open Sans' !important;
      color: #2C2C2C !important;
}
.projectDetails .MuiTypography-root{
  font-size: 13px !important;
    font-weight: 400 !important;
    font-family: 'Open Sans' !important;
}
.projectDetails span{
  line-height: 17px;
}
.projectDetails span div{
  font-size: 13px;
}
  