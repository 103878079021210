@import '../../../styles/variables.module.scss';
// Banner Content CSS Start
.homepageHeaderbg {
  // height: 280px;
  background-color: #d6e5f4;
  background-position: left bottom;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 180px;
  margin-bottom: 10rem;
  @media (max-width: 600px) {
    margin-bottom: 3rem;
    height: 145px;
  }
  @media (max-width: 1025px) {
    margin-bottom: 6rem;
  }

}

.icoBtn {
  padding: 4px 0 !important;
  color: #000 !important;
  border-radius: 0 !important;
}
.positionR {
  position: relative;
}


.headerBtn {
  display: flex;
  text-align: center;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.headerSpan {
  color: white;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  top: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .mobBg {
// background-position: 120px top;
// background-repeat: no-repeat;
// position: relative;
// background-image: url('../../../public/static/images/mobbanner-bg.svg');
// height: 270px;
// width: 100%;
// }

.bannerMainTitle {
  font-size: 26px;
  font-weight: 700;
  margin: 0px 0px;
  letter-spacing: -0.3px !important;
  line-height: 41px;
  color: #171717;
  margin-top: 10px;
  @media (max-width: 600px) {
    font-size: 20px !important;
    line-height: 24px;
    letter-spacing: -0.2px !important;
    margin-bottom: 0.6rem;
    text-align: center;
    color: #171717;
    width: 73%;
    margin-left: auto;
    margin-right: auto;
  }
  // @media (max-width: 768px) {
  //   font-size: 20px !important;
  //   line-height:25px;
  // }
@media (min-width: 768px) and (max-width: 1024px) {
    font-size: 26px;
    line-height: 25px;
    padding-top: 10px;
    text-align: center;
}
  @media (max-width: 1025px) {
    margin-top: 0px;
    text-align: center;
  }
  @media (max-width: 1900px) {
    text-align: center;
  }
}
.orangeTitle {
  color: $darkorange-color;
}
.orangeBlue {
  color: $primary-color;
}
.subTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  line-height: 24px;
  letter-spacing: -0.18px;
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    width: 51%;
    position: absolute;
    // display: inline-block;
    // vertical-align: top;
  }
}

.imgData img {
  @media (max-width: 600px) {
    position: relative;
    // display: inline-block;
    // vertical-align: top;
    padding-left: 131px;
  }
  @media (max-width: 361px) {
    position: relative;
    padding-left: 115px;
  }
  @media (max-width: 321px) {
    position: relative;
    padding-left: 99px;
  }
}
.subTitle sup {
  font-size: 11px;
  @media (max-width: 600px) {
    font-size: 9px;
  }
}

.bkgSearch {
  // background-image: url('../../../public/static/images/bg-search.svg');
  background-repeat: no-repeat;
  position: relative;
  height: 53px;
  display: flex;
  background-size: contain;
  align-items: center;
  text-align: center;
  justify-content: flex-end;
  font-size: 13px;
  font-weight: 700;
  background-position: 26%;
  a {
    color: #333333 !important;
  }
  span {
    font-size: 16px;
    font-weight: 700;
    color: #333333;
  }

  @media (max-width: 600px) {
    background-size: cover;
    height: 38px;
  }
}

@media screen and (max-width: 576px) {
  .bkgSearch span {
    font-size: 14px;
    color: #333333;
  }
}
.hbBannerinfo {
  display: flex;
  margin: 0px auto;
  align-items: center;
  width: 100%;
  margin-top: 0px;
  justify-content: space-between;
  padding-top: 1rem;
}

.bannerText {
  text-align: center;
}
.bannerText h2 {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  letter-spacing: -0.3px;
}
.bannerTextMain h2 {
  font-size: 30px;
  font-weight: 800;
  color: #fff;
  letter-spacing: -0.3px;
}
.bannerTextMain {
  text-align: center;
}
.bannerCall {
  display: flex;
  justify-content: flex-end;
}
.bannerCall img {
  height: 71px;
}
.callText {
  margin-left: 20px;
  width: 120px;
}
.callText h5 {
  font-size: 14px;
  color: #d6d6d6;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: -0.14px;
}
.callnowBtn {
  margin-top: 10px;
  background: none;
  padding: 5px 15px;
  border: 1px solid #f9f9f9;
  color: #f9f9f9;
  font-weight: 700;
  border-radius: 5px;
  cursor: pointer;
}

.nextarrow {
  height: 24px !important;
  margin-left: 5px;
}

// Banner Content CSS End

// Search Bar section CSS start-----------------------
.searchbarOuter {
  width: 100%;
  //height: 130px;
  // max-width: 918px;
  // margin: 0px auto;
  border: 3px solid #d3e0ed;
  background-color: #fff;
  border-radius: 18px;
  // position: absolute;
  // left: 50%;
  // bottom: 0%;
  // transform: translate(-50%, 50%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  overflow: hidden;
}
.searchbartopSection {
  //display: flex;
  padding: 0.5rem 1rem 1rem 1rem;
  position: relative;
  height: 48px;
}
.searchBottomSection {
  height: 64px;
}
.activeModule {
  color: '#fff';
  font-weight: '700 !important';
  opacity: 1;
}
.searchBarWidth {
  width: 486px;
  margin-top: -8px;
  position: absolute;
  z-index: 9;
  height: auto;
  display: flex;
  align-items: center;

  input {
    margin-left: 10px;
  }
}
.searchBarWidth img {
  padding: 0px 15px !important;
}
.inputSearch {
  width: 100% !important;
}
.searchTopBg {
  //position: absolute;
  top: 1px;
  right: 16%;
}
.searchTopBg img {
  height: 40px;
  margin-top: -7px;
}

.selltitle {
  font-size: 16px;
  font-weight: 700;
  color: #171717;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  span {
    font-size: 13px;
    font-weight: 700;
    margin-left: 5px;
    margin-right: 5px;
  }
}
.nice_select_font {
  font-size: 22px !important;
  font-weight: 700;
}
.font {
  padding-left: 1rem;
  height: 64px;
  position: absolute;
  top: 44px;
  width: 24%;
}
.font span {
  font-size: 20px !important;
  font-weight: 700;
}

// Search Bar section CSS End-----------------------

//media query  ---------------------------------------------------------
@media only screen and (min-width: 600px) and (max-width: 960px) {
  .homepageHeaderbg {
    text-align: center;
    margin-bottom: 5rem !important;
    // padding-left: 20px;
  }
  .mobBg {
    background-position: center 50px;
  }
}
@media only screen and (min-width: 300px) and (max-width: 600px) {
  .hbBannerinfo {
    margin-top: 0px;
    display: flex;
    justify-content: center;
  }
  .homepageHeaderbg {
    padding: 25px 0px;
    background-size: 100%;
  }
  .searchTopBg img {
    height: 32px;
  }
  .searchbartopSection {
    height: 40px;
    padding: 0.5rem 1rem 1rem 0.5rem;
  }
  .searchBottomSection {
    height: 48px;
  }
  .activeModule {
    width: 33px;
  }
  .searchTopBg {
    left: 0%;
    position: relative;
  }
  .textRight {
    text-align: right;
  }
  .selltitle {
    margin-top: -2px;
    margin-left: -30px;
  }
  .searchBarWidth {
    margin-left: 10px;
  }
  .inputSearch input {
    font-size: 12px !important;
  }
  .font {
    height: 40px !important;
    top: 38px !important;
    margin-top: 6px;
    width: 27% !important;
    background: none;
  }
  .callText {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1024px) {
  .homepageHeaderbg {
    background-size: 100%;
  }
  .searchbarOuter {
    max-width: 520px !important;
    margin: 0px auto;
  }
  .bannerText {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .bannerText h2 {
    margin-top: 2rem;
  }
  .bannerCall {
    margin: 20px 30px;
  }
  .hbBannerinfo {
    justify-content: center;
  }
  .font {
    height: 40px !important;
    top: 38px !important;
    margin-top: 6px;
    width: 27% !important;
    background: none;
  }
  .searchTopBg img {
    height: 33px;
    margin-top: -8px;
  }
}
@media only screen and (min-width: 821px) and (max-width: 1024px) {
  .searchbarOuter {
    max-width: 710px !important;
    margin: 0px auto;
    border-radius: 10px;
  }
  .searchbartopSection {
    height: 40px;
  }
  .searchTopBg img {
    height: 32px;
    margin-top: -7px;
  }
  .searchTopBg {
    right: 20%;
  }
  .selltitle {
    margin-top: -2px;
    justify-content: start;
  }
  .font span {
    font-size: 13px !important;
  }
}
@media (max-width: 992px) {
  .searchBarWidth {
    width: auto;
    input {
      margin-left: 6px;
      width: 180px !important;
      font-size: 9px;
    }
  }

  .searchbarOuter {
    width: 100%;
    border-radius: 10px;
    margin: 0px auto;
    max-width: 820px;
  }
  .searchbartopSection {
    // padding: 0.5rem 1rem 1rem 0rem;
    height: 40px;
  }
  .font {
    background: none;
    padding-left: 0.5rem;
    //height: 56px !important;
    //top: 44px !important;
  }
  .font span {
    font-size: 13px !important;
    font-weight: 700;
  }
}

@media (max-width: 1024px) {
  .homepageHeaderbg {
    text-align: center;
    margin-bottom: 5rem !important;
    // padding-left: 20px;
  }
  .searchBottomSection {
    height: 48px;
  }
  .font {
    background: none;
    padding-left: 0.5rem;
    height: 57px;
    top: 51px;
    width: 22%;
  }

  .bannerText {
    text-align: center;
  }

  .bannerText h2 {
    text-transform: capitalize;
    line-height: 30px;
    letter-spacing: -0.12px;
    font-size: 24px;
  }
  .bannerCall {
    align-items: center;
    margin: 20px 7px;
  }
  .callText h5 {
    color: #fff;
    font-weight: 700;
  }
  .bannerCall img {
    height: 46px;
  }
  .callText {
    display: flex;
    align-items: center;
    margin-left: 8px;
  }
}

@media (max-width: 1920px) {
  .searchBarWidth {
    width: auto;
    input {
      margin-left: 6px;
      width: 220px;
    }
  }
}
