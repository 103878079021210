.root {
  top: 50%;
  left: 50%;
  width: 395px;
  padding: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background-color: none;
  border: none !important;
}

.close {
  color: #999999;
  background-color: transparent;
  float: right;
  border: none;
  position: relative;
  right: -20px;
  top: -65px;
  z-index: 99999;
  font-size: 16px;

  & svg {
    width: 25px;
    height: 25px;
  }
}

.closebtnGuide {
  position: relative;
  right: 5px;
  top: 20px;
  cursor: pointer;

  @media (max-width: 600px) {
    top: -20px;
    right: -8px;
  }
}

.closebtn {
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;

  @media (max-width: 600px) {
    top: -33px;
    right: 27px!important;
  }
}

.cancelIcon {
  margin-left: auto;
  height: 24px !important;
  width: 24px !important;
  border-color: #707070 !important;
  color: #d1d1d1 !important;
  cursor: pointer;
  position: absolute;
  right: -27px;
 @media (max-width: 600px) {
    right: -27px;
    top: 0px!important;
  }
  @media (max-width: 767px) {
    right: -27px;
    top: -20px;
  }
}
