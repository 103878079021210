.inputBorder input {
  height: 30px;
  padding: 5px;
}

.inputBorder input ::placeholder {
  font-size: 12px;
}

.listStyle {
  margin-left: 5px !important;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0px;
}
.listStyle li::marker {
  font-size: 0px;
}
.root {
  box-shadow: none!important;
    

  & > input {
    &::placeholder {
      font-size: 10px;
    }
  }
}

.chipBox {
  display: flex;
  justify-content: left;
  list-style: none;
  padding: 3px;
  margin-top: 0px;
  height: 35px;
  cursor: pointer;
  align-items: center;
font-family: 'Open Sans' !important;
 li:first-of-type {
  div {
    max-width: 150px !important;
    @media (max-width: 1024px) {
      max-width: 105px !important;
    }

    @media (max-width: 768px) {
      max-width: 150px !important;
    }
  }
}

  .MuiChip-label {
    padding-left: 2px !important;
    padding-right: 10px !important;
    width: fit-content;
  }
}


.subchip {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  margin-left: 0px !important;
  margin-right: 10px !important;
  font-family: 'Open Sans' !important;
  font-size: 14px !important;
  color: #171717 !important;
  font-weight: 500 !important;
  height: 38px !important;
  padding: 14px !important;
  width: 100% !important;
  border: 1px solid #dedede !important;

  &:hover {
    background-color: #fff !important;
  }

  img {
    margin-top: 2px !important;
    cursor: pointer !important;
  }

  .MuiChip-label {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-right: 10px !important;
  }
}

.chip {
  background-color: #ffffff !important;
  border-radius: 5px;
  margin-left: 0px;
  margin-right: 10px;
  font-size: 13px;
  color: #171717;
  font-weight: 500;
  height: 30px;
  padding: 10px 0px;
  width: 100%;

  &:hover {
    background-color: #fff;
  }
}

.numofcity {
  border: 1px solid #c5d9ed;
  height: 26px;
  padding: 5px;
  font-family: 'Open Sans, sans-serif' !important;
  font-size: 14px;
  color: #171717;
  font-weight: 500;
  border-radius: 3px;
  margin-left: 5px;
  background-color: #fff !important;

  .MuiChip-label {
    padding: 0px !important;
  }
}
.numofcity span{
  padding-left: 4px;
  padding-right: 4px;
}

.plusButton {
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #0056b233;
  border-radius: 6px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 2px;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.searchfilter {
  background-color: #f2f5fc;
  padding: 20px 17px;
}

.searchfilterdev {
  background-color: #f2f5fc;
  padding: 15px;
}
